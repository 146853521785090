export const CUSTOMERS_BATCH_DELETE_REQUEST = 'CUSTOMERS_BATCH_DELETE_REQUEST'
export const CUSTOMERS_BATCH_DELETE_SUCCESS = 'CUSTOMERS_BATCH_DELETE_SUCCESS'
export const CUSTOMERS_BATCH_DELETE_FAILURE = 'CUSTOMERS_BATCH_DELETE_FAILURE'

export const CUSTOMERS_FETCH_MANY_REQUEST = 'CUSTOMERS_FETCH_MANY_REQUEST'
export const CUSTOMERS_FETCH_MANY_SUCCESS = 'CUSTOMERS_FETCH_MANY_SUCCESS'
export const CUSTOMERS_FETCH_MANY_FAILURE = 'CUSTOMERS_FETCH_MANY_FAILURE'

export const CUSTOMERS_FETCH_REQUEST = 'CUSTOMERS_FETCH_REQUEST'
export const CUSTOMERS_FETCH_SUCCESS = 'CUSTOMERS_FETCH_SUCCESS'
export const CUSTOMERS_FETCH_FAILURE = 'CUSTOMERS_FETCH_FAILURE'

export const CUSTOMERS_SAVE_REQUEST = 'CUSTOMERS_SAVE_REQUEST'
export const CUSTOMERS_SAVE_SUCCESS = 'CUSTOMERS_SAVE_SUCCESS'
export const CUSTOMERS_SAVE_FAILURE = 'CUSTOMERS_SAVE_FAILURE'

export const EMPTY_CUSTOMERS = 'EMPTY_CUSTOMERS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
