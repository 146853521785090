import {
  USERS_BATCH_DELETE_REQUEST,
  USERS_BATCH_DELETE_SUCCESS,
  USERS_BATCH_DELETE_FAILURE,
  USERS_FETCH_MANY_REQUEST,
  USERS_FETCH_MANY_SUCCESS,
  USERS_FETCH_MANY_FAILURE,
  USERS_FETCH_REQUEST,
  USERS_FETCH_SUCCESS,
  USERS_FETCH_FAILURE,
  USERS_SAVE_REQUEST,
  USERS_SAVE_SUCCESS,
  USERS_SAVE_FAILURE,

  EMPTY_USERS,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(USERS_BATCH_DELETE_REQUEST)
    return window.axios.post('/users', { _method: 'DELETE', ids }).then(response => {
      commit(USERS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(USERS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1, extraParams = {} }) {
    commit(USERS_FETCH_MANY_REQUEST)
    return window.axios.get('/users', { params: { query, page, ...extraParams } }).then(response => {
      commit(USERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(USERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(USERS_FETCH_REQUEST)
    return window.axios.get(`/users/${id}`).then(response => {
      commit(USERS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(USERS_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, user) {
    const path = user.id ? `/users/${user.id}` : '/users'
    const method = user.id ? 'put' : 'post'

    commit(USERS_SAVE_REQUEST)
    return window.axios[method](path, user).then(response => {
      commit(USERS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(USERS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(USERS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_USERS);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
