<template>
  <resource-search @delete="() => this.$emit('delete')" @input="id => this.$emit('input', id)" module="users" :show-delete="showDelete" :error-feed="errorFeed" :error-key="errorKey" :wait-for-input="waitForInput" :fetch-params="extraFetchParams" v-model="value">
    <template #popover-list="{ result }">
        <p class="font-weight-bold mb-0">{{ result.name }}</p>
        <small class="text-muted mb-0">{{ result.email }}</small>
    </template>

    <template #selected="{ selected }">
      <b-row>
          <b-col cols="12">
            <p class="font-weight-bold mb-0">{{ selected.name }}</p>
            <small class="text-muted mb-0">{{ selected.email }}</small>
          </b-col>
      </b-row>
    </template>

    <template #selected-form-area>
      <slot name="form-area" />
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'

export default {
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    waitForInput: {
      type: Boolean,
      default: true,
    },
    errorFeed: Object,
    errorKey: String,
    type: String,
    value: Object,
  },
  computed: {
    extraFetchParams() {
      if (this.type) {
        return { type: this.type };
      }

      return {};
    }
  }
}
</script>

<style>

</style>
