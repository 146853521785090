export const USERS_BATCH_DELETE_REQUEST = 'USERS_BATCH_DELETE_REQUEST'
export const USERS_BATCH_DELETE_SUCCESS = 'USERS_BATCH_DELETE_SUCCESS'
export const USERS_BATCH_DELETE_FAILURE = 'USERS_BATCH_DELETE_FAILURE'

export const USERS_FETCH_MANY_REQUEST = 'USERS_FETCH_MANY_REQUEST'
export const USERS_FETCH_MANY_SUCCESS = 'USERS_FETCH_MANY_SUCCESS'
export const USERS_FETCH_MANY_FAILURE = 'USERS_FETCH_MANY_FAILURE'

export const USERS_FETCH_REQUEST = 'USERS_FETCH_REQUEST'
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS'
export const USERS_FETCH_FAILURE = 'USERS_FETCH_FAILURE'

export const USERS_SAVE_REQUEST = 'USERS_SAVE_REQUEST'
export const USERS_SAVE_SUCCESS = 'USERS_SAVE_SUCCESS'
export const USERS_SAVE_FAILURE = 'USERS_SAVE_FAILURE'

export const EMPTY_USERS = 'EMPTY_USERS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
