import pagination from '../../utils/pagination'
import {
  USERS_BATCH_DELETE_REQUEST, USERS_BATCH_DELETE_SUCCESS, USERS_BATCH_DELETE_FAILURE,
  USERS_FETCH_MANY_REQUEST, USERS_FETCH_MANY_SUCCESS, USERS_FETCH_MANY_FAILURE,
  USERS_FETCH_REQUEST, USERS_FETCH_SUCCESS, USERS_FETCH_FAILURE,
  USERS_SAVE_REQUEST, USERS_SAVE_SUCCESS, USERS_SAVE_FAILURE,
  EMPTY_USERS,
  CLEAR_ERRORS
} from './types'

export default {
  [USERS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [USERS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [USERS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [USERS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [USERS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [USERS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [USERS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [USERS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [USERS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [USERS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [USERS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [USERS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_USERS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
